import { gql } from 'apollo-boost'
export const USER_DEALERS = gql`
	query Dealers($_id: String) {
		user(_id: $_id) {
			_id
			dealers {
				_id
				priorityId
				# affiliateId
				name
				email
				phone
				sms
				contactName
				social {
					website
					minisite
					placeId
					fbId
					igId
				}
				address {
					fullAddress
					line1
					line2
					city
					postalCode
					country
				}
				logo
				location {
					type
					coordinates
				}
				meta {
					distance
					createdAt
					updatedAt
				}
				flag
				type
				franchise
				status
				leads {
					leadId
					bookingStatus
				}
				orders {
					orderId
					total
				}
			}
		}
	}
`
export const ALL_DEALERS = gql`
	query {
		allDealers {
			_id
			priorityId
			# affiliateId
			name
			email
			phone
			sms
			contactName
			social {
				website
				minisite
				placeId
				fbId
				igId
			}
			address {
				fullAddress
				line1
				line2
				city
				postalCode
				country
			}
			logo
			location {
				type
				coordinates
			}
			meta {
				distance
				createdAt
				updatedAt
			}
			flag
			type
			franchise
			status
		}
	}
`
export const CREATE_DEALER = gql`
	mutation newDealer(
		$name: String!
		$email: [String!]
		$phone: String!
		$sms: String
		$contactName: String
		$social: DealerSocial
		$priorityId: Int!
		$logo: String
		$getCoordinates: String
		$address: AddressInput!
		$flag: [String]
		$type: [String]
		$franchise: String
		$user: ID
	) {
		createDealer(
			name: $name
			email: $email
			phone: $phone
			sms: $sms
			contactName: $contactName
			social: $social
			priorityId: $priorityId
			logo: $logo
			getCoordinates: $getCoordinates
			address: $address
			flag: $flag
			type: $type
			franchise: $franchise
			user: $user
		) {
			_id
			name
			email
			phone
			sms
			contactName
			social {
				minisite
				placeId
				fbId
				igId
			}
			priorityId
			affiliateId
			logo
			location {
				coordinates
			}
			address {
				fullAddress
				line1
				line2
				city
				state
				postalCode
				country
			}
			meta {
				createdAt
				updatedAt
			}
			flag
			type
			franchise
			status
		}
	}
`
export const UPDATE_DEALERS = gql`
	mutation updateDealer(
		$_id: ID!
		$name: String
		$email: [String]
		$phone: String
		$sms: String
		$contactName: String
		$social: DealerSocial
		$priorityId: Int
		$affiliateId: Int
		$logo: String
		$address: AddressInput
		$getCoordinates: String
		$flag: [String]
		$type: [String]
		$franchise: String
		$status: String
		$user: ID
	) {
		updateDealer(
			_id: $_id
			name: $name
			email: $email
			phone: $phone
			sms: $mobile
			contactName: $contactName
			social: $social
			priorityId: $priorityId
			affiliateId: $affiliateId
			logo: $logo
			getCoordinates: $getCoordinates
			address: $address
			flag: $flag
			type: $type
			franchise: $franchise
			status: $status
			user: $user
		) {
			_id
			name
			email
			phone
			sms
			contactName
			social {
				minisite
				placeId
				fbId
				igId
			}
			priorityId
			affiliateId
			logo
			location {
				coordinates
			}
			address {
				fullAddress
				line1
				line2
				city
				state
				postalCode
				country
			}
			meta {
				createdAt
				updatedAt
			}
			flag
			type
			franchise
			status
		}
	}
`
export const CLOSEST_DEALER = gql`
	query dealerByClosest(
		$fullAddress: String
		$postalCode: String
		$coordinates: [Float]
		$flag: String
		$type: String
	) {
		dealerByClosest(
			fullAddress: $fullAddress
			postalCode: $postalCode
			coordinates: $coordinates
			flag: $flag
			type: $type
		) {
			salesForceId
		}
	}
`
export const FIND_DEALERS = gql`
	query dealersByDistance(
		$query: String
		$coordinates: [Float!]
		$maxDistance: Int
		$limit: Int
	) {
		dealersByDistance(
			query: $query
			coordinates: $coordinates
			maxDistance: $maxDistance
			limit: $limit
		) {
			_id
			affiliateId
			name
			email
			phone
			social {
				website
				minisite
				placeId
				fbId
				igId
			}
			address {
				fullAddress
				line1
				line2
				city
				postalCode
				country
			}
			logo
			location {
				coordinates
			}
			meta {
				distance
			}
			flag
			type
			franchise
			status
		}
	}
`
export const DEALERS = gql`
	query allDealers($limit: Int, $page: Int, $sort: SortInput, $query: String) {
		dealers(limit: $limit, page: $page, sort: $sort, query: $query) {
			pageInfo {
				docsCount
				limit
				page
				pagesCount
				nextPage
				prevPage
				hasNextPage
				hasPrevPage
			}
			edges {
				_id
				priorityId
				# affiliateId
				name
				email
				phone
				sms
				contactName
				social {
					website
					minisite
					placeId
					fbId
					igId
				}
				address {
					fullAddress
					line1
					line2
					city
					postalCode
					country
				}
				logo
				location {
					type
					coordinates
				}
				meta {
					distance
					createdAt
					updatedAt
				}
				flag
				type
				franchise
				status
				leads {
					leadId
					bookingStatus
				}
				orders {
					orderId
					total
				}
			}
		}
	}
`
